import { ReactNode, createContext, useState } from 'react';

type CorrelationId = string | undefined;

export const CorrelationIdContext = createContext<CorrelationId>(undefined);
export const SetCorrelationIdContext = createContext<(correlationId: CorrelationId) => void>(() => {});

export const CorrelationIdProvider = ({ children }: { children: ReactNode }) => {
    const [correlationId, setCorrelationId] = useState<CorrelationId>(undefined);

    return (
        <SetCorrelationIdContext.Provider value={setCorrelationId}>
            <CorrelationIdContext.Provider value={correlationId}>{children}</CorrelationIdContext.Provider>
        </SetCorrelationIdContext.Provider>
    );
};
