import useSWR from 'swr';
import { runQuery } from 'utils/run-query';

export const useProductDqrQuery = <T = any[]>(key: string, productId?: string, fieldName = 'id') =>
    useSWR<{
        results: T[];
    }>(productId ? [key, productId, fieldName] : null, ([_, productId, fieldName]) =>
        runQuery({
            name: key,
            pageSize: 1000,
            params: [
                {
                    name: fieldName as string,
                    value: productId as string,
                    operation: '='
                }
            ]
        })
    );
