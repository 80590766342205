import { ImporterConfig } from '../../../../types/ImporterConfig.type';

export const config: ImporterConfig = {
    name: 'Dayrize Data Import',
    metadata: {
        sidebarConfig: {
            showSidebar: false
        }
    },
    namespace: 'dayrize-importer',
    autoConfigure: true
} as const;
