import { EditData } from 'edit-data';
import { links } from 'links';
import { paths } from 'paths';
import { ProductWithScoringResults, useProducts } from 'products/use-products';
import { QueryResultModal } from 'queries/query-result-modal';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useOrganizationId } from 'shared/use-organization-id';
import { Nullable } from 'types';
import { Dimension } from 'types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical, TbRefresh } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Anchor, Group, Menu, Text, Title, rem } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { FlagName } from 'utils/get-flag-value';
import { useProductFilters } from '../shared/use-product-filters';
import { DEFAULT_COLUMNS, ProductColumnFilter, keyToColumnMapping } from './product-columns-filter';

export const Products = () => {
    const [columns, setColumns] = useLocalStorage<Array<string>>({
        key: 'product-columns',
        defaultValue: DEFAULT_COLUMNS
    });
    const { t } = useTranslation();
    const { filters, ...rest } = useProductFilters();
    const organizationId = useOrganizationId();
    const { isEnabled } = useFeatureEnabled();
    const [product, setProduct] = useState<Nullable<ProductWithScoringResults>>(null);

    const {
        error,
        data,
        isLoading,
        limit,
        onLimitChange,
        page,
        onPage,
        order,
        onOrderChange,
        search,
        onSearchChange,
        mutate,
        orderDirection,
        isValidating,
        queryParams
    } = useProducts(filters);
    const navigate = useNavigate();
    const shouldDisableEditButton = data?.total_rows === undefined ? true : isLoading || isValidating || data.total_rows > 5000;

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group gap={rem(2)}>
                <Title size="h3">{t('products')}</Title>
                <MoreInfoHoverCard>
                    <Text size="sm" c="dimmed">
                        Allows you to perform actions with regard to your products, such as{' '}
                        <Anchor target="_blank" href={links.customerService.scoreReport}>
                            viewing score reports
                        </Anchor>{' '}
                        or their{' '}
                        <Anchor target="_blank" href={links.customerService.productHistory}>
                            scoring history
                        </Anchor>
                        .
                    </Text>
                </MoreInfoHoverCard>
            </Group>
            <Text c="dimmed" mb="md">
                {t('productsDescription')}
            </Text>

            <Section>
                <Table
                    search={search}
                    onSearchChange={onSearchChange}
                    expandPageSizeOptions={true}
                    actions={
                        <ProductFilters additionalFilters={<ProductColumnFilter value={columns} onChange={setColumns} />} {...rest}>
                            <ActionIcon data-testid="refresh" variant="default" size="lg" loading={isValidating} onClick={() => mutate()}>
                                <TbRefresh />
                            </ActionIcon>
                            <EditData
                                disabled={shouldDisableEditButton}
                                totalRows={data?.total_rows ?? 0}
                                queryParams={Array.isArray(queryParams) ? queryParams : []}
                            />
                            <DownloadButton
                                disabled={!data?.results}
                                exports={[
                                    {
                                        name: 'products',
                                        data: data?.results
                                    }
                                ]}
                            />
                        </ProductFilters>
                    }
                    isLoading={isLoading}
                    data={data?.results}
                    orderDirection={orderDirection}
                    columns={[
                        {
                            key: 'sku',
                            name: t('sku')
                        },
                        ...columns.map((key) => keyToColumnMapping(key, organizationId)),
                        {
                            key: 'actions',
                            name: '',
                            render: (_, row) => (
                                <Group justify="flex-end">
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.sku}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            {row.total_score && (
                                                <>
                                                    <Menu.Item
                                                        onClick={() => {
                                                            navigate(
                                                                `/organization/${organizationId}/products/${row.id}/score-report/${
                                                                    Dimension.Total
                                                                }?name=${encodeURIComponent(row.name)}&sku=${row.sku}`
                                                            );
                                                        }}
                                                    >
                                                        {t('scoreReport')}
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        onClick={() => {
                                                            navigate(paths.productHistory(organizationId, row.id));
                                                        }}
                                                    >
                                                        {t('scoringHistory')}
                                                    </Menu.Item>
                                                </>
                                            )}
                                            <Menu.Item onClick={() => setProduct(row)}>{t('scoreCalculationData')}</Menu.Item>

                                            {isEnabled(FlagName.ProductImpactSimulator) && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        navigate(paths.impactSimulator(organizationId, row.id));
                                                    }}
                                                >
                                                    {t('impactSimulator')}
                                                </Menu.Item>
                                            )}
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                            )
                        }
                    ]}
                    rowKey={(row) => `${row.sku}__${row.id}`}
                    limit={limit}
                    onLimitChange={onLimitChange}
                    page={page}
                    onPage={onPage}
                    order={order}
                    onOrderChange={onOrderChange}
                />
            </Section>
            <QueryResultModal
                title={product?.name}
                query={
                    product
                        ? {
                              name: 'product',
                              params: [
                                  {
                                      name: 'id',
                                      operation: '=',
                                      value: product?.id
                                  }
                              ]
                          }
                        : undefined
                }
                isOpen={!!product}
                onClose={() => setProduct(null)}
            />
        </>
    );
};
