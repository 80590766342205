import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { Stack, Text, Title } from '@mantine/core';
import { YouTube } from '../YouTube';

type VideoWidgetProps = {
    translationPrefix: string;
    youtubeId: string;
};

export const VideoWidget = ({ translationPrefix, youtubeId }: VideoWidgetProps) => {
    const { t } = useTranslation('translation', { keyPrefix: translationPrefix });

    return (
        <Section>
            <Stack h="100%" justify="space-between">
                <div>
                    <Title mb="md" order={2}>
                        {t('title')}
                    </Title>
                    <Text>{t('description')}</Text>
                </div>
                <YouTube title={t('title')} id={youtubeId} />
            </Stack>
        </Section>
    );
};
