import { Flex } from '@mantine/core';
import { ProductScoreSVG } from '../svg/ProductScoreSVG';

export const ProductScoreFrameContent = () => {
    return (
        <Flex justify="center" align="center" h="4.75rem">
            <ProductScoreSVG />
        </Flex>
    );
};
