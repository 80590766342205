import { ApiKeys } from 'account-management/api-keys';
import { AuditLog } from 'account-management/audit-log';
import { AddOrganization } from 'account-management/auth/add-organization';
import { AuthGuard } from 'account-management/auth/auth-guard';
import { EmailSignIn } from 'account-management/auth/email-sign-in';
import { EmailSignInComplete } from 'account-management/auth/email-sign-in-complete';
import { GuestGuard } from 'account-management/auth/guest-guard';
import { OrganizationCreationPending } from 'account-management/auth/organizaton-creation-pending';
import { PermissionError } from 'account-management/auth/permission-error';
import { RequestAccessError } from 'account-management/auth/request-access-error';
import { SignIn } from 'account-management/auth/sign-in';
import { Groups } from 'account-management/groups';
import { GroupDetail } from 'account-management/groups/group-detail';
import { CreateOrganization } from 'account-management/organizations/create-organization';
import { Namespaces } from 'account-management/organizations/namespaces';
import { Organizations } from 'account-management/organizations/organizations';
import { UserSettings } from 'account-management/user-settings';
import { Users } from 'account-management/users';
import { Materials } from 'customer-success/materials';
import { Dashboard } from 'dashboards';
import { AdvancedDataIngestion } from 'data-management/advanced-data-ingestion';
import { BulkImportEdit } from 'data-management/bulk-import-edit';
import { DataIngestion } from 'data-management/data-ingestion';
import { ColumnMappingConfiguration } from 'data-management/data-ingestion/column-mapping-configuration';
import { DownloadScoreData } from 'data-management/download-score-data/download-score-data';
import { HoldingArea } from 'data-management/holding-area';
import { RuleConfig } from 'data-management/rule-config';
import { RuleConfigDetail } from 'data-management/rule-config/rule-config-detail';
import { Utilities } from 'data-management/utilities';
import { ActionableInsights } from 'insights-explorer/actionable-insights';
import { BrandScoreComparsion } from 'insights-explorer/brand-score-comparison';
import { CategoryScoreComparison } from 'insights-explorer/category-score-comparision';
import { CircularityOfMaterials } from 'insights-explorer/circularity-of-materials';
import { ClimateImpactsOfMaterials } from 'insights-explorer/climate-impacts-of-materials';
import { DataQualityInsights } from 'insights-explorer/data-quality-insights';
import { DataQualityRating } from 'insights-explorer/dataQualityRating';
import { EcosystemImpactsOfMaterials } from 'insights-explorer/ecosystem-impacts-of-materials';
import { EnterpriseScoreComparison } from 'insights-explorer/enterprise-score-comparision';
import { MaterialInsights } from 'insights-explorer/materialsInsights';
import { Prism } from 'insights-explorer/prism';
import { ProductComparisonDashboard } from 'insights-explorer/product-comparison-dashboard';
import { ProductScoreComparison } from 'insights-explorer/product-score-comparison';
import { Scorecards } from 'insights-explorer/scorecards';
import { Home } from 'onboarding/home';
import { Products } from 'products';
import { Circularity } from 'products/circularity';
import { ClimateImpact } from 'products/climate-impact';
import { DataQuality } from 'products/data-quality';
import { EcosystemImpact } from 'products/ecosystem-impact';
import { ImpactSimulator } from 'products/impact-simulator';
import { LivelihoodsAndWellbeing } from 'products/livelihoods-and-wellbeing';
import { ProductHistory } from 'products/product-history';
import { Purpose } from 'products/purpose';
import { Summary } from 'products/summary';
import { CheckOrganization } from 'shared/check-organization';
import { ErrorFallback } from 'shared/error-fallback';
import { Layout } from 'shared/layout';
import { NotFound } from 'shared/not-found';
import { Dimension } from 'types/shared';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ScrollToTop from 'utils/scroll-to-top';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <AuthGuard>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <ScrollToTop />
                    <Outlet />
                </ErrorBoundary>
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <CheckOrganization />
            },
            {
                path: '/organization/create',
                element: <AddOrganization />
            },
            {
                path: '/organization/pending',
                element: <OrganizationCreationPending />
            },
            {
                path: '/permission-error',
                element: <PermissionError />
            },
            {
                path: '/request-access',
                element: <RequestAccessError />
            },
            {
                path: 'error',
                element: <ErrorFallback />
            },
            {
                path: 'docs',
                async lazy() {
                    const { Docs } = await import('onboarding/docs');
                    return { Component: Docs };
                }
            },
            {
                path: '*',
                element: <NotFound />
            }
        ]
    },
    {
        path: '/account',
        element: (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <GuestGuard>
                    <ScrollToTop />
                    <Outlet />
                </GuestGuard>
            </ErrorBoundary>
        ),
        children: [
            {
                path: 'sign-in',
                element: <SignIn />
            },
            {
                path: 'email',
                element: <EmailSignIn />
            },
            {
                path: 'email/complete',
                element: <EmailSignInComplete />
            }
        ]
    },
    {
        path: '/organization/:organizationId',
        element: (
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AuthGuard>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <ScrollToTop />
                        <Layout />
                    </ErrorBoundary>
                </AuthGuard>
            </QueryParamProvider>
        ),
        children: [
            {
                path: 'home',
                element: <Home />
            },
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: 'ingest',
                element: <DataIngestion />
            },
            {
                element: <AdvancedDataIngestion />,
                path: 'advanced-ingest'
            },
            {
                path: 'product-upload',
                async lazy() {
                    const { Product } = await import('data-management/data-ingestion/product-upload/product');
                    return { Component: Product };
                }
            },
            {
                path: 'materials',
                element: <Materials />
            },
            {
                path: 'column-mapping',
                element: <ColumnMappingConfiguration />
            },
            {
                path: 'namespaces',
                element: <Namespaces />
            },
            {
                path: 'users',
                element: <Users />
            },
            {
                path: 'organizations',
                element: <Organizations />
            },
            {
                path: 'organizations/create/:type',
                element: <CreateOrganization />
            },
            {
                path: 'bulk',
                element: <BulkImportEdit />
            },
            {
                path: 'user-settings',
                element: <UserSettings />
            },
            {
                path: 'audit-log',
                element: <AuditLog />
            },
            {
                path: 'groups',
                element: <Groups />
            },
            {
                path: 'groups/:id',
                element: <GroupDetail />
            },
            {
                path: 'api-keys',
                element: <ApiKeys />
            },
            {
                path: 'holding-area',
                element: <HoldingArea />
            },
            {
                path: 'queries',
                lazy: () => import('queries')
            },
            {
                path: 'queries/:id',
                lazy: () => import('queries/query-detail')
            },
            {
                path: 'rule-config',
                element: <RuleConfig />
            },
            {
                path: 'rule-config/:id',
                element: <RuleConfigDetail />
            },
            {
                path: 'utilities',
                element: <Utilities />
            },
            {
                path: 'brand-score-comparison',
                element: <BrandScoreComparsion />
            },
            {
                path: 'category-score-comparison',
                element: <CategoryScoreComparison />
            },
            {
                path: 'product-score-comparison',
                element: <ProductScoreComparison />
            },
            {
                path: 'score-comparison/:id',
                element: <EnterpriseScoreComparison />
            },
            {
                path: 'actionable-insights',
                element: <ActionableInsights />
            },
            {
                path: 'data-quality-rating',
                element: <DataQualityRating />
            },
            {
                path: 'material-insights',
                element: <MaterialInsights />
            },
            {
                path: 'product-comparison-dashboard',
                element: <ProductComparisonDashboard />
            },
            {
                path: 'scorecards',
                element: <Scorecards />
            },
            {
                path: 'data-quality-insights',
                element: <DataQualityInsights />
            },
            {
                path: 'download-score-data',
                element: <DownloadScoreData />
            },
            {
                path: 'ecosystem-impacts-of-materials',
                element: <EcosystemImpactsOfMaterials />
            },
            {
                path: 'climate-impacts-of-materials',
                element: <ClimateImpactsOfMaterials />
            },
            {
                path: 'circularity-of-materials',
                element: <CircularityOfMaterials />
            },
            {
                path: 'prism',
                element: <Prism />
            },
            {
                path: 'products',
                element: <Products />
            },
            {
                path: `products/:id/history`,
                element: <ProductHistory />
            },

            {
                path: `products/:id/impact-simulator`,
                element: <ImpactSimulator />
            },
            {
                path: `products/:id/score-report/${Dimension.Total}`,
                element: <Summary />
            },
            {
                path: `products/:id/score-report/${Dimension.Circularity}`,
                element: <Circularity />
            },
            {
                path: `products/:id/score-report/${Dimension.Ecosystem}`,
                element: <EcosystemImpact />
            },
            {
                path: `products/:id/score-report/${Dimension.Climate}`,
                element: <ClimateImpact />
            },
            {
                path: `products/:id/score-report/${Dimension.Purpose}`,
                element: <Purpose />
            },
            {
                path: `products/:id/score-report/${Dimension.Livelihood}`,
                element: <LivelihoodsAndWellbeing />
            },
            {
                path: `products/:id/score-report/${Dimension.DataQualityRating}`,
                element: <DataQuality />
            },
            {
                path: '*',
                element: <NotFound />
            }
        ]
    }
]);

export const Router = () => <RouterProvider router={router} />;
