import { Flex } from '@mantine/core';
import { DashboardSVG } from '../svg/DashboardSVG';

export const DashboardFrameContent = () => {
    return (
        <Flex justify="center" align="center" h="4.75rem">
            <DashboardSVG />
        </Flex>
    );
};
