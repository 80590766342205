import { links } from 'links';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, Group, Text, Title } from '@mantine/core';
import styles from './importer-heading.module.css';

export const ImporterHeading = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'advancedDataIngestionPage' });

    return (
        <>
            <Group className={styles.importerHeadingGroup}>
                <Title order={3}>{t('title')}</Title>
                <MoreInfoHoverCard>
                    <Text size="sm" c="dimmed">
                        <Trans
                            i18nKey="advancedDataIngestionPage.moreInfoText"
                            components={{
                                ValidateAnchor: <Anchor target="_blank" href={links.customerService.validate} />,
                                DataAnchor: <Anchor target="_blank" href={links.customerService.dataIngestion} />
                            }}
                        />
                    </Text>
                </MoreInfoHoverCard>
            </Group>
            <Text c="dimmed" mb="md">
                {t('description')}
            </Text>
        </>
    );
};
