import { ReactNode, createContext, useState } from 'react';

export const CorrelationIdLoadingContext = createContext<boolean>(false);
export const SetCorrelationIdLoadingContext = createContext<(loading: boolean) => void>(() => {});

export const CorrelationIdLoadingProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState(false);

    return (
        <SetCorrelationIdLoadingContext.Provider value={setLoading}>
            <CorrelationIdLoadingContext.Provider value={loading}>{children}</CorrelationIdLoadingContext.Provider>
        </SetCorrelationIdLoadingContext.Provider>
    );
};
