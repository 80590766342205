import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useFileId } from '../../../../hooks/useFileId';
import { SetCorrelationIdContext } from '../../../../providers/CorrelationIdProvider';
import { useCorrelationId } from '../../hooks/useCorrelationId';
import { SetCorrelationIdLoadingContext } from '../../providers/CorrelationIdLoadingProvider';

export const AuditAction = () => {
    const { fileId } = useFileId();

    return fileId ? <Action fileId={fileId} /> : null;
};

const Action = ({ fileId }: { fileId: string }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'advancedDataIngestionPage.audit.action' });
    const setCorrelationId = useContext(SetCorrelationIdContext);
    const setCorrelationIdLoading = useContext(SetCorrelationIdLoadingContext);
    const { get, loading, correlationId } = useCorrelationId(fileId);

    useEffect(() => {
        if (correlationId && !loading) {
            setCorrelationId(correlationId);
            setCorrelationIdLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correlationId, loading]);

    const handleAuditActionClick = useCallback(() => {
        setCorrelationIdLoading(true);
        get();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Button onClick={handleAuditActionClick} loading={loading}>
            {t('text')}
        </Button>
    );
};
