import { ReactNode, createContext, useState } from 'react';
import { EditQuery } from '../types/EditQuery.type';

export const EditDataQueryContext = createContext<EditQuery>({ params: [], startNewSession: false });
export const SetEditDataQueryContext = createContext<(editQuery: EditQuery) => void>(() => {});

export const EditDataQueryParamsProvider = ({ children }: { children: ReactNode }) => {
    const [queryParams, setQueryParams] = useState<EditQuery>({ params: [], startNewSession: false });

    return (
        <EditDataQueryContext.Provider value={queryParams}>
            <SetEditDataQueryContext.Provider value={setQueryParams}>{children}</SetEditDataQueryContext.Provider>
        </EditDataQueryContext.Provider>
    );
};
