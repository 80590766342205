import api from '@flatfile/api';
import { useListener } from '@flatfile/react';
import { useCallback, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useFileId } from '../../../../hooks/useFileId';
import { FileInfo } from '../../../../types/FileInfo.type';
import { IMPORTER_NAMESPACE } from '../../constants';
import { ConfirmModal } from './ConfirmModal';

export const OnImporterSubmit = () => {
    const { setFileId } = useFileId();
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
    const [opened, { open, close }] = useDisclosure();
    const showConfirmModal = fileInfo && opened;

    useListener((listener) => {
        listener.namespace([IMPORTER_NAMESPACE], (spaceListener) => {
            spaceListener.on('job:completed', { job: 'workbook:submit' }, async (event) => {
                const { jobId } = event.context;
                const {
                    data: { info }
                } = await api.jobs.get(jobId);
                // TODO: handle info being undefined to the user or throw an error to the error boundary?
                if (!info || info.length === 0) {
                    return;
                }
                // TODO: handle fail json parse error or this can bubble up the error boundary?
                const { uploadedFileName, uploadedFileId } = JSON.parse(info);
                setFileInfo({
                    filename: uploadedFileName,
                    fileId: uploadedFileId
                });
                setFileId(uploadedFileId);
                open();
            });
        });
    });

    const handleOnClose = useCallback(() => {
        setFileInfo(null);
        close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return showConfirmModal ? <ConfirmModal fileInfo={fileInfo} onClose={handleOnClose} /> : null;
};
