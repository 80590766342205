export const DashboardSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="221" height="45" viewBox="0 0 221 45" fill="none">
        <path d="M2 43C2 40.7909 3.79086 39 6 39H22C24.2091 39 26 40.7909 26 43V44H2V43Z" fill="#EBE8E8" />
        <path d="M29 36C29 33.7909 30.7909 32 33 32H49C51.2091 32 53 33.7909 53 36V44H29V36Z" fill="#EBE8E8" />
        <path d="M57 28C57 25.7909 58.7909 24 61 24H77C79.2091 24 81 25.7909 81 28V44H57V28Z" fill="#EBE8E8" />
        <path d="M168 8C168 5.79086 169.791 4 172 4H188C190.209 4 192 5.79086 192 8V44H168V8Z" fill="#EBE8E8" />
        <path d="M112 17C112 14.7909 113.791 13 116 13H132C134.209 13 136 14.7909 136 17V44H112V17Z" fill="#EBE8E8" />
        <path d="M85 20C85 17.7909 86.7909 16 89 16H105C107.209 16 109 17.7909 109 20V44H85V20Z" fill="#EBE8E8" />
        <path d="M196 4C196 1.79086 197.791 0 200 0H216C218.209 0 220 1.79086 220 4V44H196V4Z" fill="#EBE8E8" />
        <path d="M140 12C140 9.79086 141.791 8 144 8H160C162.209 8 164 9.79086 164 12V44H140V12Z" fill="#FFD643" />
        <path d="M0.647461 44L219.647 44" stroke="#3A3A3A" strokeOpacity="0.2" strokeLinecap="round" />
    </svg>
);
