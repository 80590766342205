import { Section } from 'shared/section';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TbArrowRight } from 'react-icons/tb';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Group, Paper, Space, Stack, Text, Title, useComputedColorScheme, useMantineTheme } from '@mantine/core';

type NavigateIconWidgetProps = {
    translationPrefix: string;
    link: {
        path: string;
    };
    FrameContent: () => ReactElement;
};

export const NavigateIconWidget = ({ translationPrefix, link: { path }, FrameContent }: NavigateIconWidgetProps) => {
    const { t } = useTranslation('translation', { keyPrefix: translationPrefix });
    const { organizationId } = useParams();
    const computedColorScheme = useComputedColorScheme('light');
    const theme = useMantineTheme();
    const paperBackgroundColor = computedColorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0];

    return (
        <Section>
            <Stack justify="space-between" h="100%">
                <Paper p="md" shadow="none" bg={paperBackgroundColor}>
                    <FrameContent />
                </Paper>
                <div>
                    <Space h="md" />
                    <Title order={2}>{t('title')}</Title>
                    <Stack>
                        <Text c="dimmed">{t('description')}</Text>
                        <Anchor component={Link} to={`/organization/${organizationId}/${path}`}>
                            <Group align="center">
                                <Text>{t('linkText')}</Text>
                                <TbArrowRight />
                            </Group>
                        </Anchor>
                    </Stack>
                </div>
            </Stack>
        </Section>
    );
};
