import { useCallback } from 'react';
import { useSessionStorage } from '@mantine/hooks';

const defaultValue = null;

export const useFileId = () => {
    const [fileId, setFileId] = useSessionStorage<string | null>({
        key: 'advanced-data-ingestion-file-id',
        getInitialValueInEffect: false,
        defaultValue
    });

    const resetFileId = useCallback(() => {
        setFileId(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        fileId,
        setFileId,
        resetFileId
    };
};
