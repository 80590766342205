import { AspectRatio } from '@mantine/core';

type YouTubeProps = {
    title: string;
    id: string;
};

export const YouTube = ({ title, id }: YouTubeProps) => (
    <AspectRatio ratio={16 / 9}>
        <iframe
            title={title}
            src={`https://www.youtube.com/embed/${id}`}
            loading="lazy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    </AspectRatio>
);
