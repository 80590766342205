import { Dimension } from 'types/shared';

export const paths = {
    root: () => `/`,
    error: () => `/error`,
    signIn: () => `/account/sign-in`,
    email: () => `/account/email`,
    requestAccess: () => `/request-access`,
    createMainOrganization: () => `/organization/create`,
    home: (organizationId?: string) => `/organization/${organizationId}/home`,
    userSettings: (organizationId?: string) => `/organization/${organizationId}/user-settings`,
    dashboard: (organizationId?: string) => `/organization/${organizationId}/dashboard`,
    products: (
        organizationId?: string,
        params?: {
            category?: string;
            brand?: string;
        }
    ) => {
        let path = `/organization/${organizationId}/products`;
        if (params?.category) {
            path += `?categories=${params.category}`;
        }
        if (params?.brand) {
            path += `?brands=${params.brand}`;
        }
        return path;
    },
    productHistory: (organizationId?: string, id?: string) => `/organization/${organizationId}/products/${id}/history`,
    impactSimulator: (organizationId?: string, id?: string) => `/organization/${organizationId}/products/${id}/impact-simulator`,
    scoreReport: (
        organizationId?: string,
        key?: string,
        params?: {
            name?: string;
        }
    ) => {
        let path = `/organization/${organizationId}/products/${key}/score-report/${Dimension.Total}`;
        if (params?.name) {
            path += `?name=${encodeURIComponent(params.name)}`;
        }
        return path;
    },
    queries: (organizationId?: string) => `/organization/${organizationId}/queries`,

    queryDetail: (organizationId?: string, key?: string) => `/organization/${organizationId}/queries/${key}`,
    apiKeys: (organizationId?: string) => `/organization/${organizationId}/api-keys`,
    insights: {
        materialInsights: (organizationId?: string) => `/organization/${organizationId}/material-insights`,
        categoryScoreComparison: (organizationId?: string) => `/organization/${organizationId}/category-score-comparison`,
        brandScoreComparison: (organizationId?: string) => `/organization/${organizationId}/brand-score-comparison`,
        productScoreComparison: (organizationId?: string) => `/organization/${organizationId}/product-score-comparison`,
        prism: (organizationId?: string) => `/organization/${organizationId}/prism`,
        enterpriseScoreComparison: (organizationId?: string, key?: string) => `/organization/${organizationId}/score-comparison/${key}`,
        actionableInsightsComparison: (organizationId?: string) => `/organization/${organizationId}/actionable-insights`,
        dataQualityRating: (organizationId?: string) => `/organization/${organizationId}/data-quality-rating`,
        productComparisonDashboard: (organizationId?: string) => `/organization/${organizationId}/product-comparison-dashboard`,
        scorecards: (organizationId?: string) => `/organization/${organizationId}/scorecards`,
        dataQualityInsights: (organizationId?: string) => `/organization/${organizationId}/data-quality-insights`
    },
    dataManagement: {
        ingest: (organizationId?: string) => `/organization/${organizationId}/ingest`,
        advancedIngest: (organizationId?: string) => `/organization/${organizationId}/advanced-ingest`,
        columnMapping: (organizationId?: string) => `/organization/${organizationId}/column-mapping`,
        downloadScoreData: (organizationId?: string) => `/organization/${organizationId}/download-score-data`,
        ruleConfig: (organizationId?: string) => `/organization/${organizationId}/rule-config`,
        ruleConfigDetail: (organizationId?: string, key?: string) => `/organization/${organizationId}/rule-config/${key}`,
        bulkImportEdit: (organizationId?: string) => `/organization/${organizationId}/bulk`,
        holdingArea: (organizationId?: string, fileId?: string) =>
            `/organization/${organizationId}/holding-area${fileId ? `?file=${fileId}` : ''}`
    },
    accountManagement: {
        auditLog: (organizationId?: string) => `/organization/${organizationId}/audit-log`,
        groups: (organizationId?: string) => `/organization/${organizationId}/groups`,
        groupDetail: (organizationId?: string, key?: string) => `/organization/${organizationId}/groups/${key}`,
        users: (organizationId?: string) => `/organization/${organizationId}/users`,
        organizations: (organizationId?: string) => `/organization/${organizationId}/organizations`,
        createOrganization: (organizationId?: string) => `/organization/${organizationId}/organizations/create/partner`
    },
    customerSuccess: {
        singleProductUpload: (organizationId?: string) => `/organization/${organizationId}/product-upload`,
        namespaces: (organizationId?: string) => `/organization/${organizationId}/namespaces`,
        createOrganization: (organizationId?: string) => `/organization/${organizationId}/organizations/create/admin`,
        utilities: (organizationId?: string) => `/organization/${organizationId}/utilities`,
        materials: (organizationId?: string) => `/organization/${organizationId}/materials`
    }
};
