import { Space, useFlatfile, useListener } from '@flatfile/react';
import { useAuth } from 'account-management/auth/use-auth';
import { EditDataQueryContext, SetEditDataQueryContext } from 'edit-data/providers/EditDataQueryParamsProvider';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { MantineTheme, useMantineTheme } from '@mantine/core';
import { useSpaceSession } from '../../../../hooks/useSpaceSession';
import { RequirementsContext } from '../../../../providers/RequirementsProvider';
import { SpaceConfig } from '../../../../types/SpaceConfig.type';
import { IMPORTER_NAMESPACE } from '../../constants';
import { config } from './config';
import './importer-workspace.css';

// no props to control this so having to use this hack solution
const removeFlatfileCloseButton = () => {
    const closeButton = document.querySelector('.flatfile-close-button');
    if (closeButton) {
        closeButton.remove();
    }
};

const mapThemeToFlatfileTheme = (theme: MantineTheme) => ({
    root: {
        buttonBorderRadius: '0.5rem',
        fontFamily: theme.fontFamily,
        primaryColor: theme.colors.blue[8]
    }
});

export const ImporterWorkspace = () => {
    const strictModeRef = useRef(false);
    const { spaceSession, setSpaceSession } = useSpaceSession();
    const { openPortal, closePortal } = useFlatfile();
    const spaceId = spaceSession?.spaceId;

    useListener((listener) => {
        listener.namespace([IMPORTER_NAMESPACE], (spaceListener) => {
            spaceListener.on('job:ready', { job: 'space:configure' }, (event) => {
                const { spaceId } = event.context;
                const { _accessToken } = event;

                // TODO: need to handle this scenario to the user
                if (_accessToken == null || spaceId == null) {
                    return;
                }

                setSpaceSession({
                    spaceId,
                    accessToken: _accessToken
                });
            });
        });
    });

    useEffect(() => {
        openPortal();
        removeFlatfileCloseButton();

        return () => {
            // a workaround for react strict mode when components mount, unmount and remount
            // we only want the closePortal function to fire once on an actual unmount
            // i.e. in any other environment that is not using react strict mode
            if (process.env.NODE_ENV !== 'development') {
                closePortal();
                return;
            }
            // this is the ref to check for strict mode only in the development environment
            if (strictModeRef.current) {
                closePortal();
            }
            strictModeRef.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{spaceId ? <ResumeSpace id={spaceId} /> : <NewSpace />}</>;
};

const ResumeSpace = memo(({ id }: { id: string }) => <Space id={id} />);

const NewSpace = memo(() => {
    const editDataQueryContext = useContext(EditDataQueryContext);
    const setEditDataQueryParams = useContext(SetEditDataQueryContext);
    const [{ params, startNewSession }] = useState(() => editDataQueryContext);
    const theme = useMantineTheme();
    const { idToken } = useAuth();
    const requirements = useContext(RequirementsContext);
    const spaceConfig: SpaceConfig = {
        ...config,
        metadata: {
            ...config.metadata,
            dayrizeAccessToken: idToken?.token,
            dayrizeMinimalRequirements: startNewSession ? false : requirements === 'minimal',
            theme: mapThemeToFlatfileTheme(theme),
            ...(startNewSession && {
                dayrizePreloadQueryParameters: {
                    query_name: 'product',
                    query_params: [...params]
                }
            })
        }
    };

    useEffect(() => {
        if (editDataQueryContext.startNewSession) {
            setEditDataQueryParams({ params: [], startNewSession: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setEditDataQueryParams]);

    return <Space config={spaceConfig} />;
});
