import { getValue } from 'firebase/remote-config';
import { remoteConfig } from 'lib/firebase';

export enum FlagName {
    ProductImpactSimulator = 'product_impact_simulator',
    Prism = 'prism',
    BulkImportEdit = 'bulk_import_edit',
    DataLevelPermissions = 'data_level_permissions',
    PartnerOrganizations = 'partner_organizations',
    HoldingArea = 'holding_area',
    TagFiltering = 'tag_filtering',
    EnterpriseScoreComparison = 'enterprise_score_comparison',
    LookerDashboards = 'looker_dashboards',
    LookerDataQualityDashboards = 'looker_data_quality_dashboard',
    ActionableInsights = 'actionable_insights',
    MinimalRequirements = 'minimal_requirements',
    DataQualityRating = 'dqr',
    AdvancedDataIngestion = 'advanced_data_ingestion'
}

export enum FlagValue {
    /**
     * This flag is enabled and accessible to all users
     */
    On = 'on',

    /**
     * This flag  is accessible to all users, but in a preview state.
     * This means that the feature is not considered stable and may be subject to change.
     */
    Preview = 'preview',

    /**
     * This flag is enabled and accessible to Dayrize staff only
     */
    Internal = 'internal',

    /**
     * This flag is enable and accessible to Dayrize staff including when impersonating users in other namespaces
     */
    InternalWithImpersonation = 'internal_with_impersonation',

    /**
     * This flag is disabled and not accessible to any users
     */
    Off = 'off'
}

export const getFlagValue = (flagName: FlagName) => getValue(remoteConfig, flagName).asString() || FlagValue.Off;
