import { useFileId } from 'data-management/advanced-data-ingestion/hooks/useFileId';
import { useSpaceSession } from 'data-management/advanced-data-ingestion/hooks/useSpaceSession';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useOrganizationId } from 'shared/use-organization-id';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Modal, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FlagName } from 'utils/get-flag-value';
import { SetEditDataQueryContext } from './providers/EditDataQueryParamsProvider';
import { QueryParams } from './types/QueryParams.type';

export type EditDataProps = {
    disabled?: boolean;
    totalRows: number;
    queryParams: QueryParams;
};

export const EditData = memo(({ disabled = false, totalRows, queryParams }: EditDataProps) => {
    const { isEnabled } = useFeatureEnabled();
    const isEditDataEnabled = isEnabled(FlagName.AdvancedDataIngestion);
    const { t } = useTranslation('translation', { keyPrefix: 'editData' });
    const setEditDataQuery = useContext(SetEditDataQueryContext);
    const [opened, { open, close }] = useDisclosure();
    const navigate = useNavigate();
    const organizationId = useOrganizationId();
    const { resetFileId } = useFileId();
    const { spaceSession, resetSpaceSession } = useSpaceSession();
    const total = new Intl.NumberFormat().format(totalRows);
    const sessionExists = spaceSession?.spaceId;

    const resetCurrentSession = useCallback(() => {
        resetSpaceSession();
        resetFileId();
    }, [resetFileId, resetSpaceSession]);

    const startNewEditDataSession = useCallback(() => {
        setEditDataQuery({ params: queryParams, startNewSession: true });
        navigate(`/organization/${organizationId}/advanced-ingest`);
    }, [navigate, organizationId, queryParams, setEditDataQuery]);

    const handleEditDataButtonClick = useCallback(() => {
        if (sessionExists) {
            open();
            return;
        }
        startNewEditDataSession();
    }, [open, sessionExists, startNewEditDataSession]);

    return isEditDataEnabled ? (
        <>
            <Tooltip label={t('tooltip', { total })} disabled={!disabled || totalRows === 0}>
                <Button variant="outline" disabled={disabled} onClick={handleEditDataButtonClick}>
                    {t('text')}
                </Button>
            </Tooltip>
            <Modal opened={opened} onClose={close} title={t('modal.title')}>
                <Stack>
                    <Text>{t('modal.description')}</Text>
                    <Text>{t('modal.confirmAction')}</Text>
                    <Group justify="flex-end">
                        <Button onClick={close} variant="outline">
                            {t('modal.buttons.cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                resetCurrentSession();
                                startNewEditDataSession();
                            }}
                        >
                            {t('modal.buttons.confirm')}
                        </Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    ) : null;
});
