import { useCallback } from 'react';
import { useSessionStorage } from '@mantine/hooks';
import { SpaceSession } from '../types/SpaceSession.type';

const defaultValue = {
    accessToken: null,
    spaceId: null
};

export const useSpaceSession = () => {
    const [spaceSession, setSpaceSession] = useSessionStorage<SpaceSession>({
        key: 'advanced-data-ingestion-space-session',
        getInitialValueInEffect: false,
        defaultValue
    });

    const resetSpaceSession = useCallback(() => {
        setSpaceSession(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        spaceSession,
        setSpaceSession,
        resetSpaceSession
    };
};
