import { AuditLogTable } from 'account-management/audit-log/audit-log-table';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { CorrelationIdContext, SetCorrelationIdContext } from '../../../../providers/CorrelationIdProvider';

export const AuditModal = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'advancedDataIngestionPage.audit.modal' });
    const correlationId = useContext(CorrelationIdContext);
    const setCorrelationId = useContext(SetCorrelationIdContext);
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const handleOnClose = useCallback(() => {
        setCorrelationId(undefined);
    }, [setCorrelationId]);

    return correlationId ? (
        <Modal opened fullScreen={isSmallDevice} size="100%" title={t('title')} onClose={handleOnClose}>
            <Box py="md">
                <AuditLogTable prefix="file_" correlationId={correlationId} />
            </Box>
        </Modal>
    ) : null;
};
