import { useContext } from 'react';
import { CorrelationIdProvider } from './providers/CorrelationIdProvider';
import { RequirementsProvider } from './providers/RequirementsProvider';
import { SessionComponentContext, SessionProvider } from './providers/SessionProvider';

const AdvancedDataIngestionSession = () => {
    const SessionComponent = useContext(SessionComponentContext);

    return (
        <CorrelationIdProvider>
            <RequirementsProvider>
                <SessionComponent />
            </RequirementsProvider>
        </CorrelationIdProvider>
    );
};

export const AdvancedDataIngestion = () => (
    <SessionProvider>
        <AdvancedDataIngestionSession />
    </SessionProvider>
);
