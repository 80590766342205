import { ReactNode, createContext, useState } from 'react';
import { Requirements } from '../types/Requirements.type';

export const RequirementsContext = createContext<Requirements>('full');
export const SetRequirementsContext = createContext<(requirements: Requirements) => void>(() => {});

export const RequirementsProvider = ({ children }: { children: ReactNode }) => {
    const [requirements, setRequirements] = useState<Requirements>('full');

    return (
        <SetRequirementsContext.Provider value={setRequirements}>
            <RequirementsContext.Provider value={requirements}>{children}</RequirementsContext.Provider>
        </SetRequirementsContext.Provider>
    );
};
