import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Paper, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import { SetRequirementsContext } from '../../../providers/RequirementsProvider';
import styles from './new-requirements.module.css';
import FullClipboardSVG from './svg/full-clipboard.svg?react';
import MinimalClipboardSVG from './svg/minimal-clipboard.svg?react';

type NewRequirementsProps = {
    onNewRequirements: () => void;
};

export const NewRequirements = ({ onNewRequirements }: NewRequirementsProps) => {
    const { t } = useTranslation('translation', { keyPrefix: 'advancedDataIngestionPage.requirements' });
    const setRequirements = useContext(SetRequirementsContext);
    const { isEnabled } = useFeatureEnabled();
    const isMinimalRequirementsEnabled = isEnabled(FlagName.MinimalRequirements);

    const handleFullRequirementsClick = () => {
        setRequirements('full');
        onNewRequirements();
    };

    const handleMinimalRequirementsClick = () => {
        setRequirements('minimal');
        onNewRequirements();
    };

    return (
        <Container size="xl">
            <div {...(!isMinimalRequirementsEnabled && { className: styles.fullRequirementsSectionContainer })}>
                <Title className={styles.requirementsTitle} order={2}>
                    {t('title')}
                </Title>
                <div {...(isMinimalRequirementsEnabled && { className: styles.requirementsSectionContainer })}>
                    <Paper {...(!isMinimalRequirementsEnabled && { className: styles.fullRequirementsSectionStandAlone })}>
                        <UnstyledButton className={styles.requirementsAction} onClick={handleFullRequirementsClick}>
                            <Stack className={styles.requirementsStack} justify="space-between">
                                <Title order={3}>{t('full.title')}</Title>
                                <div className={styles.imageGrid}>
                                    <div className={styles.fullImageContainer}>
                                        <FullClipboardSVG />
                                    </div>
                                </div>
                                <Text c="dimmed">{t('full.description')}</Text>
                            </Stack>
                        </UnstyledButton>
                    </Paper>
                    {isMinimalRequirementsEnabled ? (
                        <Paper>
                            <UnstyledButton className={styles.requirementsAction} onClick={handleMinimalRequirementsClick}>
                                <Stack className={styles.requirementsStack} justify="space-between">
                                    <Title order={3}>{t('minimal.title')}</Title>
                                    <div className={styles.imageGrid}>
                                        <div className={styles.minimalImageContainer}>
                                            <MinimalClipboardSVG />
                                        </div>
                                    </div>
                                    <Text c="dimmed">{t('minimal.description')}</Text>
                                </Stack>
                            </UnstyledButton>
                        </Paper>
                    ) : null}
                </div>
            </div>
        </Container>
    );
};
