import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { SetCorrelationIdContext } from '../../../../providers/CorrelationIdProvider';
import { FileInfo } from '../../../../types/FileInfo.type';
import { useCorrelationId } from '../../hooks/useCorrelationId';
import styles from './confirm-modal.module.css';

type ConfirmModalProps = {
    fileInfo: FileInfo;
    onClose: () => void;
};

export const ConfirmModal = ({ fileInfo: { filename, fileId }, onClose }: ConfirmModalProps) => {
    const { t } = useTranslation('translation', { keyPrefix: 'advancedDataIngestionPage.onSubmit.success' });
    const setCorrelationId = useContext(SetCorrelationIdContext);
    const { get, loading, correlationId } = useCorrelationId(fileId);

    useEffect(() => {
        if (correlationId && !loading) {
            setCorrelationId(correlationId);
            onClose();
        }
    }, [correlationId, loading, onClose, setCorrelationId]);

    return (
        <Modal
            opened
            onClose={onClose}
            title={t('title')}
            withCloseButton={!loading}
            closeOnEscape={!loading}
            closeOnClickOutside={!loading}
        >
            <Text className={styles.modalText}>{t('filename', { filename })}</Text>
            <Text className={styles.modalText}>{t('info')}</Text>
            <Stack>
                <Text className={styles.confirmModalTexts}>{t('confirmMessage')}</Text>
                <Group justify="flex-end">
                    <Button variant="outline" onClick={onClose} disabled={loading}>
                        {t('cancelButtonText')}
                    </Button>
                    <Button onClick={get} loading={loading}>
                        {t('confirmButtonText')}
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
};
