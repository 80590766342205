import structuredClone from '@ungap/structured-clone';
import { useIngestion } from 'data-management/data-ingestion/use-ingestion';
import { ValidationResultModal } from 'data-management/data-ingestion/validation-result-modal';
import { ConfirmationModal } from 'shared/confirmation-modal';
import { DownloadButton } from 'shared/download-button';
import { ImportButton } from 'shared/import-button';
import { PreviewBadge } from 'shared/preview-badge';
import { Section } from 'shared/section';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { ElementRef, useLayoutEffect, useRef, useState } from 'react';
import DataGrid, { FillEvent } from 'react-data-grid';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle } from 'react-icons/tb';
import { Alert, Box, Button, Group, Paper, Select, Stack, Text, Title, Tooltip } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { NameConfirmationModal } from '../../shared/name-confirmation-modal';
import { DataRow, createEmptyRow } from './create-empty-row';
import {
    dataRowToProductSchema,
    isPrimaryPackagingMaterial,
    isProductMaterial,
    isSecondaryPackagingMaterial,
    isTertiaryPackagingMaterial,
    productSchemaToDataRow
} from './data-row-product-schema-conversion';
import {
    getGeneralColumns,
    getLivelihoodsAndWellbeingColumns,
    getMaterialsColumns,
    getOptionalGeneralColumns,
    getProductionColumns,
    getSustainabilityColumns
} from './get-columns';
import { getDataRowsFromFile } from './get-data-rows-from-file';
import { getMaxCount } from './get-max-count';
import { getPasteUpdatedRows } from './get-paste-updated-rows';
import styles from './index.module.css';
import { isValidRow } from './is-valid-row';
import { resetRowsForCounts } from './reset-row-for-counts';
import { Counts, defaultCounts, getSessionState, persistSessionState } from './session-state';
import { setMaterialTotals } from './set-material-totals';
import { setRelatedFields } from './set-related-fields';

const STORAGE_KEY = 'bulk-edit';

type ContextMenu = {
    rowIdx: number;
    top: number;
    left: number;
} | null;

export const BulkImportEdit = ({
    initialTab = 'general',
    initialContextMenu = null,
    initialRows: initialPropRows = [],
    variant = 'bulk',
    storageKey = STORAGE_KEY,
    onUploadComplete
}: {
    initialTab?: string;
    initialContextMenu?: ContextMenu;
    initialRows?: DataRow[];
    variant?: 'bulk' | 'holding';
    storageKey?: string;
    onUploadComplete?: () => void;
}) => {
    const { t } = useTranslation();
    const { isEnabled } = useFeatureEnabled();
    const menuRef = useRef<ElementRef<'div'>>(null);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [showIgnoreInvalidRowsModal, setShowIgnoreInvalidRowsModal] = useState(false);
    const [isProcessingFile, setIsProcessingFile] = useState(false);
    const [activeSection, setActiveSection] = useQueryParam('section', withDefault(StringParam, initialTab));
    const [contextMenuProps, setContextMenuProps] = useState<ContextMenu>(initialContextMenu);
    const [showValidationResultModal, setShowValidationResultModal] = useState(false);
    const [showConfirmNameModal, setShowConfirmNameModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const isContextMenuOpen = contextMenuProps !== null;

    // Count values for sections of the upload. This is to track the correct number of colums to display
    // and perform index based matching when changing to data row to the product schema.
    const { rows: initialRows, ...initialCounts } = getSessionState(storageKey, initialPropRows);

    const [counts, setCounts] = useState<Counts>(initialCounts);

    const [rows, setRows] = useState<DataRow[]>(initialRows);

    const { upload, validate } = useIngestion();

    const handleFileSelected = async (file: File | undefined) => {
        setIsProcessingFile(true);
        const data = await getDataRowsFromFile(file);
        if (!data) {
            setIsProcessingFile(false);
            return;
        }

        try {
            const transformedRows =
                data.map((current, index) => {
                    const row = productSchemaToDataRow(current, index + 1);
                    setMaterialTotals(row);
                    const withRelatedFieldsSet = setRelatedFields(row);
                    const { errors, invalidFields } = isValidRow(withRelatedFieldsSet);
                    withRelatedFieldsSet.validation_errors = errors;
                    withRelatedFieldsSet.invalid_fields = invalidFields;
                    return withRelatedFieldsSet;
                }) ?? [];

            const maxTransportCount = getMaxCount(data.map((current) => current.transports?.length));
            const maxEnergySourcesCount = getMaxCount(
                data.map((current) => current.production?.energy_consumption?.energy_sources?.length)
            );
            const maxEcolabelCount = getMaxCount(data.map((current) => current?.eco_labels?.length));
            const maxProductMaterialsCount = getMaxCount(data.map((current) => current.materials?.filter(isProductMaterial).length));
            const maxPrimaryPackagingMaterialsCount = getMaxCount(
                data.map((current) => current.materials?.filter(isPrimaryPackagingMaterial).length)
            );
            const maxSecondaryPackagingMaterialsCount = getMaxCount(
                data.map((current) => current.materials?.filter(isSecondaryPackagingMaterial).length)
            );
            const maxTertiaryPackagingMaterialsCount = getMaxCount(
                data.map((current) => current.materials?.filter(isTertiaryPackagingMaterial).length)
            );

            const newCounts = {
                transportCount: maxTransportCount,
                energySourceCount: maxEnergySourcesCount,
                ecolabelCount: maxEcolabelCount,
                materialCount: maxProductMaterialsCount,
                primaryPackagingMaterialsCount: maxPrimaryPackagingMaterialsCount,
                secondaryPackagingMaterialsCount: maxSecondaryPackagingMaterialsCount,
                tertiaryPackagingMaterialsCount: maxTertiaryPackagingMaterialsCount
            };

            setCounts(newCounts);
            setRows(transformedRows);
            setIsProcessingFile(false);
            persistSessionState(storageKey, transformedRows, newCounts);
        } catch (error) {
            console.error(error);
            showErrorMessage(t('failedToImport'));
            setIsProcessingFile(false);
        }
    };

    const handleRequestUpload = async () => {
        if (rows.find((current) => current.validation_errors.length > 0)) {
            setShowIgnoreInvalidRowsModal(true);
        } else {
            setShowConfirmNameModal(true);
        }
    };

    const handleUpdateRows = (rows: DataRow[]) => {
        const updatedRows = rows.map((current) => {
            const newCurrent = structuredClone(current);
            setMaterialTotals(newCurrent);
            const withRelatedFieldsSet = setRelatedFields(newCurrent);
            const { errors, invalidFields } = isValidRow(withRelatedFieldsSet);
            withRelatedFieldsSet.validation_errors = errors;
            withRelatedFieldsSet.invalid_fields = invalidFields;
            return withRelatedFieldsSet;
        });

        persistSessionState(storageKey, updatedRows, counts);
        setRows(updatedRows);
    };

    const handleUpdateRowsWithCounts = (newCounts: Partial<Counts>) => {
        const updatedCounts = {
            ...counts,
            ...newCounts
        };
        const rowsWithUpdatedCounts = rows.map((row) => resetRowsForCounts(row, updatedCounts));
        setCounts(updatedCounts);
        handleUpdateRows(rowsWithUpdatedCounts);
    };

    const handleReset = () => {
        setRows([]);
        setCounts(defaultCounts);
        persistSessionState(storageKey, [], defaultCounts);
    };

    const handleUpload = async (name: string) => {
        const convertedRows = rows.map((row) => dataRowToProductSchema(row));
        if (convertedRows.length === 0) {
            showErrorMessage(t('noValidProductsToUpload'));
            return;
        }

        const jsonlStr = convertedRows.map((item) => JSON.stringify(item)).join('\n');
        const newFile = new File([jsonlStr], `${name}.jsonl`);

        const result = await validate(newFile);

        if (result.detail.length > 0) {
            setErrors(result.detail);
            setShowValidationResultModal(true);
            return;
        }

        await upload({
            file: newFile,
            file_category_type: 'Default_JSONL',
            tags: ['bulk']
        });

        showSuccessMessage(t('uploadHasBeenCompleted'));
        handleReset();
        if (onUploadComplete) onUploadComplete();
    };

    const handleFill = ({ columnKey, sourceRow, targetRow }: FillEvent<DataRow>): DataRow => {
        return { ...targetRow, [columnKey]: sourceRow[columnKey as keyof DataRow] };
    };

    useLayoutEffect(() => {
        if (!isContextMenuOpen) return;

        function onClick(event: MouseEvent) {
            if (event.target instanceof Node && menuRef.current?.contains(event.target)) {
                return;
            }
            setContextMenuProps(null);
        }

        document.addEventListener('click', onClick);

        return () => {
            document.removeEventListener('click', onClick);
        };
    }, [isContextMenuOpen]);

    const sections = [
        {
            value: 'general',
            label: t('necessaryProductInformation'),
            columns: getGeneralColumns({
                transportCount: counts.transportCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        transportCount: value
                    });
                }
            })
        },
        {
            value: 'materials',
            label: t('productMaterialDetails'),
            columns: getMaterialsColumns({
                materialCount: counts.materialCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        materialCount: value
                    });
                }
            })
        },
        {
            value: 'primary-packaging',
            label: t('primaryPackagingMaterials'),
            columns: getMaterialsColumns({
                materialCount: counts.primaryPackagingMaterialsCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        primaryPackagingMaterialsCount: value
                    });
                },
                keyPrefix: 'materials_primary_'
            })
        },
        {
            value: 'secondary-packaging',
            label: t('secondaryPackagingMaterials'),
            columns: getMaterialsColumns({
                materialCount: counts.secondaryPackagingMaterialsCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        secondaryPackagingMaterialsCount: value
                    });
                },
                keyPrefix: 'materials_secondary_'
            })
        },
        {
            value: 'tertiary-packaging',
            label: t('tertiaryPackagingMaterials'),
            columns: getMaterialsColumns({
                materialCount: counts.tertiaryPackagingMaterialsCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        tertiaryPackagingMaterialsCount: value
                    });
                },
                keyPrefix: 'materials_tertiary_'
            })
        },
        {
            value: 'general-optional',
            label: t('additionalProductInformation'),
            columns: getOptionalGeneralColumns({
                ecolabelCount: counts.ecolabelCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        ecolabelCount: value
                    });
                }
            })
        },
        {
            value: 'sustainability',
            label: t('optionalSustainabilityProductInformation'),
            columns: getSustainabilityColumns()
        },
        {
            value: 'production',
            label: t('optionalProductionDetails'),
            columns: getProductionColumns({
                energySourceCount: counts.energySourceCount,
                onCountChange: (value) => {
                    handleUpdateRowsWithCounts({
                        energySourceCount: value
                    });
                }
            })
        },
        {
            value: 'livelihoods',
            label: t('optionalLivelihoodsWWellbeingDetails'),
            columns: getLivelihoodsAndWellbeingColumns()
        }
    ];

    const current = sections.find((current) => current.value === activeSection);
    const isEmpty = rows.length === 0;
    const hasNextSection = sections.findIndex((current) => current.value === activeSection) < sections.length - 1;
    const isBulk = variant === 'bulk';

    return (
        <>
            {isBulk && (
                <Box mb="md">
                    <Group gap="xs">
                        <Title size="h3">{t('bulkImportAndEdit')}</Title>
                        {isEnabled(FlagName.BulkImportEdit, 'preview') && <PreviewBadge />}
                    </Group>
                    <Text c="dimmed">{t('modifyAndUploadMultipleProducts')}</Text>
                </Box>
            )}

            <Section shadow={isBulk ? 'sm' : 'none'} withBorder={isBulk} p={isBulk ? 'md' : 'none'}>
                <Group mb="md" align="center" justify="space-between">
                    <Select
                        allowDeselect={false}
                        data={sections}
                        w="100%"
                        maw={320}
                        value={activeSection}
                        onChange={(value) => {
                            if (value) setActiveSection(value);
                        }}
                    />
                    <Group gap="sm">
                        {isBulk && (
                            <ImportButton
                                tooltip={t('importProductDescription')}
                                isLoading={isProcessingFile}
                                onChange={handleFileSelected}
                            >
                                {t('import')}
                            </ImportButton>
                        )}
                        {isEmpty ? (
                            <Button type="button" onClick={() => setRows([createEmptyRow(1)])}>
                                {t('addProduct')}
                            </Button>
                        ) : (
                            <DownloadButton
                                disabled={rows.length === 0}
                                exports={[
                                    {
                                        name: 'bulk_import_edit',
                                        data: rows.map((row) => dataRowToProductSchema(row))
                                    }
                                ]}
                            />
                        )}
                    </Group>
                </Group>

                {isEmpty && (
                    <Alert icon={<TbAlertCircle size={20} />} title={t('addProduct')} color="blue" p="md">
                        {isBulk ? t('addProductDescription') : t('addProductDescriptionHoldingArea')}
                    </Alert>
                )}

                {current && !isEmpty && (
                    <DataGrid
                        onCellKeyDown={async ({ column, rowIdx }, event) => {
                            if (event.metaKey && event.key === 'v' && column.renderEditCell) {
                                event.preventDefault();
                                event.preventGridDefault();
                                handleUpdateRows(await getPasteUpdatedRows(rows, rowIdx, column));
                            }
                        }}
                        rowHeight={40}
                        headerRowHeight={40}
                        columns={current.columns}
                        rows={rows}
                        onFill={handleFill}
                        onRowsChange={handleUpdateRows}
                        style={{ maxHeight: isBulk ? '63vh' : '75vh', minHeight: isBulk ? '320px' : '75vh' }}
                        className={styles.grid}
                        defaultColumnOptions={{
                            resizable: true
                        }}
                        onCellContextMenu={({ row }, event) => {
                            event.preventGridDefault();
                            event.preventDefault();
                            setContextMenuProps({
                                rowIdx: rows.indexOf(row),
                                top: event.clientY,
                                left: event.clientX
                            });
                        }}
                    />
                )}
                {!isEmpty && (
                    <Group align="center" justify="space-between" mt="md" gap="sm">
                        <Tooltip label={t('clearTooltipDescription')}>
                            <Button variant="outline" color="red" onClick={() => setShowConfirmDeleteModal(true)}>
                                {t('clear')}
                            </Button>
                        </Tooltip>

                        <Group align="center" justify="flex-end">
                            <Tooltip label={t('addProductTooltipDescription')}>
                                <Button
                                    variant="outline"
                                    type="button"
                                    onClick={() => setRows((rows) => [...rows, createEmptyRow(rows.length + 1)])}
                                >
                                    {t('addProduct')}
                                </Button>
                            </Tooltip>
                            {hasNextSection && (
                                <Tooltip label={t('nextSectionTooltipDescription')}>
                                    <Button
                                        variant="outline"
                                        type="button"
                                        onClick={() => {
                                            setActiveSection(
                                                sections[sections.findIndex((current) => current.value === activeSection) + 1].value
                                            );
                                        }}
                                    >
                                        {t('nextSection')}
                                    </Button>
                                </Tooltip>
                            )}

                            <Tooltip label={t('uploadTooltipDescription')}>
                                <Button onClick={handleRequestUpload}>{isBulk ? t('upload') : t('reupload')}</Button>
                            </Tooltip>
                        </Group>
                    </Group>
                )}
            </Section>
            <NameConfirmationModal isOpen={showConfirmNameModal} onClose={() => setShowConfirmNameModal(false)} onUpload={handleUpload} />
            <ConfirmationModal
                variant="delete"
                title={t('clearAllProducts')}
                isOpen={showConfirmDeleteModal}
                onClose={() => {
                    setShowConfirmDeleteModal(false);
                }}
                onConfirm={async () => {
                    handleReset();
                    setShowConfirmDeleteModal(false);
                }}
            >
                {t('clearAllProductsDescription')}
            </ConfirmationModal>
            <ConfirmationModal
                variant="delete"
                isOpen={showIgnoreInvalidRowsModal}
                title={t('rowsValidationFailed')}
                onClose={() => setShowIgnoreInvalidRowsModal(false)}
                onConfirm={async () => {
                    setRows((rows) => rows.filter((row) => row.validation_errors?.length === 0));
                    setShowIgnoreInvalidRowsModal(false);
                    showSuccessMessage(t('invalidRowsRemoved'));
                    setShowConfirmNameModal(true);
                }}
            >
                {t('rowsValidationFailedDescription')}
            </ConfirmationModal>

            {isContextMenuOpen &&
                createPortal(
                    <Paper
                        p="md"
                        ref={menuRef}
                        style={
                            {
                                position: 'fixed',
                                zIndex: 99999,
                                top: contextMenuProps.top,
                                left: contextMenuProps.left
                            } as unknown as React.CSSProperties
                        }
                    >
                        <Stack gap="xs">
                            <button
                                className={styles.contextButton}
                                type="button"
                                onClick={() => {
                                    const { rowIdx } = contextMenuProps;
                                    setRows([...rows.slice(0, rowIdx), ...rows.slice(rowIdx + 1)]);
                                    setContextMenuProps(null);
                                }}
                            >
                                {t('deleteRow')}
                            </button>
                            <button
                                className={styles.contextButton}
                                type="button"
                                onClick={() => {
                                    const { rowIdx } = contextMenuProps;
                                    setRows([...rows.slice(0, rowIdx), createEmptyRow(rows.length + 1), ...rows.slice(rowIdx)]);
                                    setContextMenuProps(null);
                                }}
                            >
                                {t('addNewProductAbove')}
                            </button>
                            <button
                                className={styles.contextButton}
                                type="button"
                                onClick={() => {
                                    const { rowIdx } = contextMenuProps;
                                    setRows([...rows.slice(0, rowIdx), structuredClone(rows[rowIdx]), ...rows.slice(rowIdx)]);
                                    setContextMenuProps(null);
                                }}
                            >
                                {t('duplicateRow')}
                            </button>
                        </Stack>
                    </Paper>,
                    document.body
                )}

            <ValidationResultModal
                errors={errors}
                isOpen={showValidationResultModal}
                onClose={() => {
                    setErrors([]);
                    setShowValidationResultModal(false);
                }}
            />
        </>
    );
};
