import { FlatfileProvider } from '@flatfile/react';
import { Group } from '@mantine/core';
import { AuditAction } from './components/audit/AuditAction';
import { AuditModal } from './components/audit/AuditModal';
import { ImporterHeading } from './components/heading/ImporterHeading';
import { StartNewSession } from './components/session/StartNewSession';
import { OnImporterSubmit } from './components/submit/OnImporterSubmit';
import { ImporterWorkspace } from './components/workspace/ImporterWorkspace';
import { CorrelationIdLoadingProvider } from './providers/CorrelationIdLoadingProvider';

const PUBLISHABLE_KEY = import.meta.env.VITE_FLATFILE_PK ?? '';

type ImporterProps = {
    accessToken?: string;
};

export const Importer = ({ accessToken }: ImporterProps) => (
    <>
        <ImporterHeading />
        <Group mb="md">
            <CorrelationIdLoadingProvider>
                <StartNewSession />
                <AuditAction />
            </CorrelationIdLoadingProvider>
        </Group>
        <AuditModal />
        <FlatfileProvider
            {...(accessToken ? { accessToken } : { publishableKey: PUBLISHABLE_KEY })}
            config={{
                displayAsModal: false,
                mountElement: 'advanced-data-ingestion'
            }}
        >
            <ImporterWorkspace />
            <OnImporterSubmit />
        </FlatfileProvider>
    </>
);
