import useSWRMutation from 'swr/mutation';
import { components } from 'types/api';
import { useCallback } from 'react';
import { request } from 'lib/request-client';

type FileResponse = components['schemas']['FileResponse'];

export const useCorrelationId = (fileId: string) => {
    const { data, trigger, isMutating } = useSWRMutation<FileResponse>(
        `/ingest/file/${fileId}`,
        (url: string) => request.get(url) as Promise<FileResponse>
    );

    const get = useCallback(() => {
        trigger();
    }, [trigger]);

    return {
        get,
        correlationId: data?.correlation_id,
        loading: isMutating
    };
};
