import { DqrDataPoints, dqrDataPoints } from 'shared/data-quality-data-points';
import { ErrorCard } from 'shared/error-card';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { ValueWidget as ValueWidgetType } from 'types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Group, rem, Stack, Text, Title, Image, Skeleton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from '../products/summary-card.module.css';
import circularityIconSrc from '../shared/circularity.svg';
import climateChangeIconSrc from '../shared/climate-change.svg';
import dqrIconSrc from '../shared/data-rating.svg';
import ecosystemIconSrc from '../shared/ecosystem.svg';
import livelihoodsIconSrc from '../shared/livelihoods.svg';
import purposeIconSrc from '../shared/purpose.svg';
import { NoDataAlert } from './no-data-alert';
import { useAggregatedDqrReport } from './use-aggregated-dqr-report';

const RatingItem = ({ label, value, icon, isSmallDevice }: any) => {
    return (
        <Box
            mt="sm"
            key={label}
            className={classes.section}
            style={{
                borderRadius: rem(8),
                padding: rem(16)
            }}
        >
            <Image className={classes.graphic} width={72} height={72} src={icon} alt="" />
            <Stack gap={rem(4)} className={classes.inner}>
                <Group gap="xs">
                    <Text size="xl" fw={700}>
                        {label}
                    </Text>
                </Group>
                <Text c="dimmed" style={{ textAlign: 'left' }} className={classes.introduction}>
                    {'Average data quality rating '}
                    {label === 'Overall Data Quality Rating' ? (
                        ' across all products'
                    ) : (
                        <>
                            {'for the '}
                            <strong>{label.toLowerCase()}</strong> {' dimension'}
                        </>
                    )}
                </Text>
            </Stack>
            <Text
                style={{
                    fontWeight: 500,
                    lineHeight: 1,
                    fontSize: rem(32),
                    paddingRight: rem(16)
                }}
            >
                {value}%
            </Text>
        </Box>
    );
};

const RatingList = ({ ratings, isSmallDevice }: any) => {
    return (
        <>
            {ratings.map((rating: any) => (
                <RatingItem
                    key={rating.label}
                    label={rating.label}
                    value={rating.value}
                    to={rating.to}
                    color={rating.color}
                    icon={rating.icon}
                    isSmallDevice={isSmallDevice}
                />
            ))}
        </>
    );
};

export const DataQualityRating = ({ config }: { config: ValueWidgetType }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { aggregatedDqrResult, isLoading, error } = useAggregatedDqrReport();
    const { t } = useTranslation();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const toCamelCase = (str: string) => str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
    const dqrResultList = aggregatedDqrResult?.results || [];

    const dqrFields = [
        'total_primary_dqr',
        'circularity_dqr',
        'climate_dqr',
        'ecosystems_dqr',
        'livelihoods_and_wellbeing_dqr',
        'purpose_dqr'
    ];

    const dataPoints: Array<any> = [];
    const dimensionRatings: any = {};

    // Helper function to check if a key is a valid key of DqrDataPoints
    const isDqrKey = (key: string): key is keyof DqrDataPoints => {
        return key in dqrDataPoints;
    };
    const navigate = useNavigate();
    const handleClick = () => {
        if (config?.link) {
            navigate(config.link);
        }
    };

    dqrResultList.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
            const camelCaseKey = toCamelCase(key);
            if (dqrFields.includes(key)) {
                dimensionRatings[camelCaseKey] = Number(value);
            } else if (isDqrKey(key)) {
                // Safely access myDqr[key] as we have confirmed key is keyof DqrDataPoints
                dataPoints.push({
                    'data-point': dqrDataPoints[key],
                    'primary-data-percent': `${Math.floor(Number(value) * 100)}%`
                });
            }
        });
    });

    if (error) {
        return <ErrorCard error={error} />;
    }

    const overallDqrRating = [
        {
            label: 'Overall Data Quality Rating',
            value: Math.floor(dimensionRatings['totalPrimaryDqr'] * 100),
            color: '#f0f8ff',
            icon: dqrIconSrc
        }
    ];

    const dqrRatingConfig = [
        { key: 'circularityDqr', color: '#a3c0f7', icon: circularityIconSrc },
        { key: 'climateDqr', color: '#0CA678', icon: climateChangeIconSrc },
        { key: 'ecosystemsDqr', color: '#9775FA', icon: ecosystemIconSrc },
        { key: 'livelihoodsAndWellbeingDqr', color: '#ff334f', icon: livelihoodsIconSrc },
        { key: 'purposeDqr', color: '#FEBF1F', icon: purposeIconSrc }
    ];

    const aggregateDqrRatings = dqrRatingConfig.map(({ key, icon }) => ({
        label: t(key),
        value: Math.floor(dimensionRatings[key] * 100),
        icon: icon
    }));

    const hasNoData = !isLoading && aggregatedDqrResult?.results?.length === 0;

    if (hasNoData) {
        return <NoDataAlert />;
    }

    return (
        <>
            {!config && (
                <Group align="center" justify="space-between" mb="md">
                    <Group gap="xs">
                        <Title size="h3">{t('dataQualityRating')}</Title>
                        <MoreInfoHoverCard>
                            <Text size="sm" c="dimmed">
                                {t('aggregatedDataQualityRating')}
                            </Text>
                        </MoreInfoHoverCard>
                    </Group>
                </Group>
            )}

            {isLoading ? (
                <Skeleton height={300} />
            ) : (
                <>
                    <Section ref={containerRef}>
                        {config && (
                            <button
                                onClick={handleClick}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: 0,
                                    width: '100%'
                                }}
                                aria-label="Data Quality Rating"
                                tabIndex={0}
                            >
                                <RatingList ratings={overallDqrRating} isSmallDevice={isSmallDevice} />
                            </button>
                        )}
                        {!config && (
                            <>
                                <RatingList ratings={overallDqrRating} isSmallDevice={isSmallDevice} />
                                <RatingList ratings={aggregateDqrRatings} isSmallDevice={isSmallDevice} />
                            </>
                        )}
                    </Section>
                    {!config && (
                        <Section style={{ cursor: 'pointer' }} ref={containerRef} mt="lg">
                            <Group style={{ cursor: 'pointer' }} align="flex-start" justify="space-between" gap="lg">
                                <Stack gap={rem(4)} className={classes.introduction}>
                                    <Title size="h4">{t('dataQualityRatingBreakdown')}</Title>
                                    <Text c="dimmed" size="sm">
                                        {t('dataQualityRatingBreakdownDescription')}
                                    </Text>
                                </Stack>
                            </Group>

                            <StaticTable
                                m={`${rem(20)} 0`}
                                rowKey={(row) => `${row.id}_${row.version}`}
                                searchable={false}
                                isLoading={isLoading}
                                data={dataPoints ?? []}
                                columns={[
                                    { key: 'data-point', name: t('dataPoint') },
                                    { key: 'primary-data-percent', name: t('overallPercentageOfPrimaryData') }
                                ]}
                            />
                        </Section>
                    )}
                </>
            )}
        </>
    );
};
