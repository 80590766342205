import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Skeleton, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useSpaceSession } from '../../../../hooks/useSpaceSession';
import { StartNewSessionContext } from '../../../../providers/SessionProvider';
import { CorrelationIdLoadingContext } from '../../providers/CorrelationIdLoadingProvider';
import styles from './start-new-session.module.css';

export const StartNewSession = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'advancedDataIngestionPage.newSession' });
    const startNewSession = useContext(StartNewSessionContext);
    const correlationIdLoading = useContext(CorrelationIdLoadingContext);
    const { spaceSession } = useSpaceSession();
    const showButton = spaceSession?.accessToken;
    const [opened, { open, close }] = useDisclosure(false);

    const handleConfirm = useCallback(() => {
        startNewSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showButton ? (
                <Button onClick={open} variant="outline" fullWidth={false} disabled={correlationIdLoading}>
                    {t('buttonText')}
                </Button>
            ) : (
                <Skeleton className={styles.startNewSessionSkeleton} visible aria-label={t('skeletonButtonAriaLabel')} />
            )}
            <Modal opened={opened} onClose={close} title={t('modal.title')}>
                <Text mb="lg">{t('modal.text')}</Text>
                <Group justify="flex-end">
                    <Button onClick={handleConfirm}>{t('modal.confirmButtonText')}</Button>
                    <Button variant="outline" onClick={close}>
                        {t('modal.cancelButtonText')}
                    </Button>
                </Group>
            </Modal>
        </>
    );
};
