import { SimpleGrid } from '@mantine/core';
import { DashboardFrameContent } from './components/frame-content/DashboardFrameContent';
import { ProductScoreFrameContent } from './components/frame-content/ProductScoreFrameContent';
import { NavigateIconWidget } from './components/widgets/NavigateIconWidget';
import { VideoWidget } from './components/widgets/VideoWidget';
import { WelcomeWidget } from './components/widgets/WelcomeWidget';
import styles from './index.module.css';

export const Home = () => (
    <SimpleGrid>
        <WelcomeWidget />
        <SimpleGrid className={styles.innerWidgetGrid}>
            <VideoWidget translationPrefix="homePage.widgets.video.dashboard" youtubeId="64ltFtrLBVE" />
            <VideoWidget translationPrefix="homePage.widgets.video.explore" youtubeId="dJtahr8aKYI" />
        </SimpleGrid>
        <SimpleGrid className={styles.innerWidgetGrid}>
            <NavigateIconWidget
                translationPrefix="homePage.widgets.navigation.dashboard"
                link={{ path: 'dashboard' }}
                FrameContent={DashboardFrameContent}
            />
            <NavigateIconWidget
                translationPrefix="homePage.widgets.navigation.productScore"
                link={{ path: 'products' }}
                FrameContent={ProductScoreFrameContent}
            />
        </SimpleGrid>
    </SimpleGrid>
);
