import { EditDataQueryContext } from 'edit-data/providers/EditDataQueryParamsProvider';
import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { Importer } from '../components/importer';
import { NewSessionRequirements } from '../components/new-session-requirements';
import { useFileId } from '../hooks/useFileId';
import { useSpaceSession } from '../hooks/useSpaceSession';

export const SessionComponentContext = createContext<() => ReactNode>(() => null);
export const StartNewSessionContext = createContext<() => void>(() => {});

const isExistingSession = (accessToken: string | null): accessToken is string => accessToken !== null;

export const SessionProvider = ({ children }: { children: ReactNode }) => {
    const { startNewSession: isNewEditDataSession } = useContext(EditDataQueryContext);
    const { resetFileId } = useFileId();
    const { spaceSession, resetSpaceSession } = useSpaceSession();
    const accessToken = spaceSession?.accessToken;
    const [SessionComponent, setSessionComponent] = useState(() => {
        if (isNewEditDataSession) {
            return () => <Importer />;
        }

        return isExistingSession(accessToken) ? () => <Importer accessToken={accessToken} /> : () => <NewSessionRequirements />;
    });

    const resetSession = useCallback(() => {
        resetSpaceSession();
        resetFileId();
    }, [resetFileId, resetSpaceSession]);

    const startNewSession = useCallback(() => {
        resetSession();
        setSessionComponent(() => () => <NewSessionRequirements />);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StartNewSessionContext.Provider value={startNewSession}>
            <SessionComponentContext.Provider value={SessionComponent}>{children}</SessionComponentContext.Provider>
        </StartNewSessionContext.Provider>
    );
};
