import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { Stack, Text, Title } from '@mantine/core';

export const WelcomeWidget = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'homePage' });

    return (
        <Section>
            <Title mb="md">{t('heading')}</Title>
            <Stack>
                <Text>{t('subHeading')}</Text>
                <Text>{t('letsGetStarted')}</Text>
            </Stack>
        </Section>
    );
};
