import { ReactNode, forwardRef } from 'react';
import { Paper, PaperProps } from '@mantine/core';
import styles from './section.module.css';

export const Section = forwardRef<HTMLDivElement, { children: ReactNode } & PaperProps>(({ children, ...rest }, ref) => {
    return (
        <Paper className={styles.section} ref={ref} {...rest}>
            {children}
        </Paper>
    );
});
