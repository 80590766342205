import styles from './product-score-svg.module.css';

export const ProductScoreSVG = () => (
    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_312_14166)">
            <path
                d="M73.3127 36.5196C73.3126 16.3505 56.9714 0.000199316 36.8135 0.00013383C16.6556 6.83451e-05 0.314513 16.3503 0.314578 36.5194C0.314644 56.6884 16.6559 73.0387 36.8138 73.0388C56.9716 73.0389 73.3128 56.6887 73.3127 36.5196ZM6.51942 36.5194C6.51937 19.779 20.0825 6.20837 36.8135 6.20842C53.5446 6.20848 67.1078 19.7792 67.1079 36.5196C67.1079 53.2599 53.5448 66.8306 36.8137 66.8305C20.0827 66.8305 6.51947 53.2597 6.51942 36.5194Z"
                fill="#282828"
                fillOpacity="0.04"
            />
        </g>
        <path
            d="M33.8744 3.23392C33.7246 1.5263 34.9887 0.00581903 36.703 0.000508971C40.8306 -0.0122779 44.9378 0.675626 48.848 2.0422C53.7367 3.75077 58.2053 6.48104 61.957 10.0516C65.7087 13.6221 68.6575 17.9512 70.6074 22.7511C72.5573 27.5509 73.4637 32.7117 73.2663 37.8903C73.069 43.069 71.7724 48.147 69.4627 52.7868C67.153 57.4267 63.8831 61.5221 59.8704 64.801C55.8577 68.0799 51.194 70.4671 46.1892 71.804C42.1861 72.8734 38.0381 73.2511 33.9232 72.9287C32.2143 72.7948 31.0697 71.184 31.349 69.4926C31.6285 67.8007 33.2274 66.6719 34.9387 66.776C38.1807 66.9731 41.4405 66.6477 44.5921 65.8058C48.746 64.6961 52.6169 62.7147 55.9475 59.9933C59.278 57.2718 61.992 53.8726 63.9091 50.0215C65.8261 46.1704 66.9023 41.9557 67.0661 37.6574C67.2299 33.3591 66.4776 29.0757 64.8592 25.0918C63.2408 21.1079 60.7933 17.5148 57.6794 14.5513C54.5655 11.5877 50.8565 9.32158 46.7989 7.90347C43.7203 6.82753 40.4945 6.25845 37.2468 6.21184C35.5324 6.18724 34.0242 4.94179 33.8744 3.23392Z"
            fill="#FFD643"
        />
        <defs>
            <filter
                id="filter0_i_312_14166"
                x="0.314575"
                y="0.00012207"
                width="72.9981"
                height="77.0387"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_312_14166" />
            </filter>
        </defs>
        <text className={styles.productScoreIconText} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
            %
        </text>
    </svg>
);
